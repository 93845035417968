import _ from 'lodash';
import React from 'react';
import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { Button, CardActions, Container, Link, Paper, Typography } from '@material-ui/core';
import { DEFAULT_LANGUAGE, LANGUAGE_VERSION } from '../properties';
import PDFComponent from './PDFComponent';

const LOGO_IMAGE_HEIGHT = '250px';
const Certificates = ({ selectedLanguage = DEFAULT_LANGUAGE }) => {
    const [certificatesData, setCertificatesData] = useState({});

    useEffect(() => {
        fetch(`content/Certificates${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setCertificatesData(json));
    }, []);

    const data = _.get(certificatesData, selectedLanguage, {});
    const certificates = _.get(data, 'certificates', []);
    return (
        <Container id="#certificates" maxWidth="lg" className="Certificates-section" disableGutters>
            <Typography variant="h5" color="primary" align="center" className="default-margin-bottom">
                {data?.title}
            </Typography>
            <div className="Certificates-section-items">
                {certificates && certificates.map(entry => {
                    const filePDF = _.get(entry, 'certificate_pdf');
                    const logoSVG = _.get(entry, 'logo_svg');
                    const logoPNG = _.get(entry, 'logo_png');
                    const actions = _.get(entry, 'actions', []);
                    return (
                        <div key={_.camelCase(entry?.title)} className="Certificates-item">
                            <Paper elevation={1} component="div" className="Certificates-item-container" tabIndex={0}>
                                <div className="Certificates-item-content">
                                    {filePDF ? (
                                        <PDFComponent
                                            file={filePDF}
                                        />
                                    ) : (
                                        <div className="Certificates-item-content-logo-certificate">
                                            <Typography gutterBottom={false} variant="h5" color="primary" align="center" className="default-margin-bottom">
                                                {entry?.title}
                                            </Typography>
                                            <Typography variant="h6" color="primary" align="center" className="default-margin-bottom">
                                                {entry?.description}
                                            </Typography>
                                            <div className="logo-container">
                                                {logoSVG && <ReactSVG
                                                    src={logoSVG}
                                                    className="logo-element"
                                                    beforeInjection={(svg) => {
                                                        svg.setAttribute('style', `height: ${LOGO_IMAGE_HEIGHT}; width: 100%`)
                                                    }}
                                                />}
                                                {logoPNG && <img
                                                    className="logo-element"
                                                    alt="avantre-cert-icon"
                                                    src={logoPNG}
                                                    title="avantre-cert-icon"
                                                    height={LOGO_IMAGE_HEIGHT}
                                                />}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {(actions.length > 0) && (
                                    <CardActions>
                                        {actions.map(action => (
                                            <Button size="small" color="primary" component={Link} href={action?.url} target="_blank">
                                                {action?.text}
                                            </Button>
                                        ))}
                                    </CardActions>
                                )}
                            </Paper>
                        </div>
                    );
                })}
            </div>
        </Container>
    );
}

export default Certificates;