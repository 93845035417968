import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Hidden, Typography } from '@material-ui/core';
import { LINK_PAGE } from '../../App';
import LabourLeasing from '../offer/LabourLeasing';
import ImageMediaCard from '../../common/ImageMediaCard';
import { LANGUAGE_VERSION } from '../../properties';

const Home = ({ breadcrumbInfo, selectedLanguage }) => {
    const [homeData, setHomeData] = useState({});
    const [offerData, setOfferData] = useState({});
    const language = selectedLanguage;

    useEffect(() => {
        fetch(`content/Home${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setHomeData(json));
        fetch(`content/Offer${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setOfferData(json));
    }, []);

    const languageHomeData = _.get(homeData, language, {});
    const languageOfferData = _.get(offerData, language, {});
    const offerLeasing = _.get(languageOfferData, 'leasing');
    const pagesLargeLinks = _.get(languageHomeData, 'pagesLargeLinks', []);

    return (
        <div className="Home">
            <section className="Home-header-container" style={{ backgroundImage: `url('/img/header-home.jpg')` }}>
                <Container maxWidth="lg" disableGutters>
                    <div className="Home-header">
                        <div className="header-text">
                            <Typography variant="h2" gutterBottom>
                                {languageHomeData?.title}
                            </Typography>
                            <Typography variant="h5" className="description">
                                {languageHomeData?.description}
                            </Typography>
                            <Button
                                className="contact-us"
                                color="secondary"
                                variant="contained"
                                size="large"
                                component={Link}
                                to={LINK_PAGE.CONTACT.path}
                            >
                                {languageHomeData?.contactUsButton || "Contact us"}
                            </Button>
                        </div>
                        <Hidden smDown>
                            <div className="header-image">
                                <img
                                    alt={languageHomeData?.headerAlt || "Avantre Group"}
                                    src={languageHomeData?.headerImage || "img/header2.png"}
                                    title={languageHomeData?.headerAlt || "Avantre Group"}
                                    height={languageHomeData?.headerImageHeight || "500px"}
                                />
                            </div>
                        </Hidden>
                    </div>
                </Container>
            </section>
            <section>
                <LabourLeasing offerLeasing={offerLeasing} languageOfferData={languageOfferData} />
            </section>
            {pagesLargeLinks && <section>
                <Container maxWidth="lg" className="Home-navigation-buttons default-margin-bottom" disableGutters>
                    <div className="navigation-buttons-container">
                        {pagesLargeLinks.map(({ linkPage, ...props }, index) => (
                            <ImageMediaCard
                                key={index + linkPage}
                                {...props}
                                link={LINK_PAGE[linkPage].path}
                            />
                        ))}
                    </div>
                </Container>
            </section>}
        </div >
    );
};

export default Home;