import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Typography } from '@material-ui/core';
import { LINK_PAGE } from '../App';
import WorkIcon from '@material-ui/icons/Work'

const OrderSection = ({ title, buttonText, link, icon }) => {
    return (
        <section id="order-section" >
            <Container maxWidth="lg" className="Order-main-container default-margin default-margin-bottom" disableGutters>
                <Typography variant="h5" color="primary" align="center">
                    {title}
                </Typography>
                <Container maxWidth="sm" align="center">
                    <Button
                        className="order-section-button"
                        variant="contained"
                        color="secondary"
                        size="large"
                        startIcon={icon || <WorkIcon />}
                        component={Link}
                        to={link || LINK_PAGE.CONTACT.path}
                    >
                        {buttonText}
                    </Button>
                </Container>
            </Container>
        </section>
    );
};

export default OrderSection;