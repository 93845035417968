import _ from 'lodash';
import { Container, Divider, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import OrderSection from '../../common/OrderSection';
import GroupIcon from '@material-ui/icons/Group';
import { LINK_PAGE } from '../../App';
import { LANGUAGE_VERSION } from '../../properties';
import Certificates from '../../common/Certificates';

const About = ({ breadcrumbInfo, selectedLanguage }) => {
    const [homeData, setHomeData] = useState({});
    const [labelsData, setLabelsData] = useState({});
    const language = selectedLanguage;

    useEffect(() => {
        fetch(`content/Home${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setHomeData(json));
        fetch(`content/Main${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setLabelsData(json));
    }, []);

    const languageData = _.get(homeData, language, {});
    const infoCards = _.get(languageData, 'infoCards', []);
    const CardRenderer = ({ title, text, imgSrc }) => (
        <div className="info-card">
            <div className="info-card-image">
                <img
                    alt={_.camelCase(title)}
                    src={imgSrc}
                    title={_.camelCase(title)}
                    height="80px"
                />
            </div>
            <div className="info-card-title">
                <Typography variant="h4" color="secondary">
                    {title}
                </Typography>
            </div>
            <div className="info-card-content">
                <Typography variant="body1" color="textSecondary">
                    {text}
                </Typography>
            </div>
        </div>
    );
    const aboutUs = _.get(languageData, 'aboutUs');
    const mainLabelsData = _.get(labelsData, language, {});
    const AvantreInfoRow = ({ icon, iconSize, children }) => (
        <div className="avantre-info-row">
            <div className="avantre-info-icon">
                <img
                    alt="avantre-info-icon"
                    src={icon}
                    title="avantre-info-icon"
                    height={iconSize || '96px'}
                />
            </div>
            <div className="avantre-info">
                {children}
            </div>
        </div>
    );

    return (
        <div className="AboutUs">
            {aboutUs && <section className="Home-about-us" style={{ backgroundImage: `url('/img/onas.jpg')` }} >
                <Container maxWidth="lg" disableGutters>
                    <Typography variant="h2" gutterBottom>
                        {aboutUs.title}
                    </Typography>
                    <Typography variant="h5">
                        {aboutUs?.description}
                    </Typography>
                </Container>
            </section>}
            <section>
                <Container maxWidth="lg" disableGutters>
                    <div className="Info-cards-container">
                        {infoCards && infoCards.map(cardProps => <CardRenderer key={cardProps.title} {...cardProps} />)}
                    </div>
                </Container>
            </section>
            <section>
                <Certificates selectedLanguage={selectedLanguage} />
            </section>
            <section>
                <Container maxWidth="lg" className="avantre-info-section default-margin" disableGutters>
                    <div className="avantre-info-container">
                        <AvantreInfoRow icon="img/icons8-people-group.png">
                            <Typography variant="h2">{mainLabelsData?.companyName || 'Avantre Group'}</Typography>
                            <Typography variant="subtitle1">{mainLabelsData?.companyOfficialName}</Typography>
                        </AvantreInfoRow>
                        <Divider variant="inset" light />
                        <AvantreInfoRow icon="img/icons8-map-marker-96.png" iconSize="48px">
                            <Typography variant="subtitle1">{mainLabelsData?.street}</Typography>
                            <Typography variant="subtitle1">{mainLabelsData?.postalCode}</Typography>
                            <Typography variant="subtitle1">{mainLabelsData?.identityNumber}</Typography>
                        </AvantreInfoRow>
                        <Divider variant="inset" light />
                        <AvantreInfoRow icon="img/icons8-call.png" iconSize="48px">
                            <Typography variant="subtitle1">{mainLabelsData?.phoneNoAvantre}</Typography>
                            <Typography variant="subtitle1">{mainLabelsData?.emailAvantre}</Typography>
                        </AvantreInfoRow>
                    </div>
                </Container>
            </section>
            {languageData && <OrderSection
                title={languageData?.collaboration}
                buttonText={languageData?.collaborationButton}
                icon={<GroupIcon />}
                link={LINK_PAGE.COLLABORATION.path}
            />}
        </div>
    );
};

export default About;