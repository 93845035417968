import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const PDFComponent = ({ file }) => {
    const [pageNumber] = useState(1);

    return (
        <Document
            file={file}
        >
            <Page pageNumber={pageNumber} />
        </Document>
    );
}

export default PDFComponent;