import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { AppBar, CssBaseline, Toolbar, Container, Button, Typography, Link as MaterialLink, Drawer, IconButton, List, ListItem, Hidden, Divider } from '@material-ui/core';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import defaultTheme from '../../common/DefaultTheme';
import SelectLanguage from '../../common/SelectLanguage';
import { languages, LINK_PAGE } from '../../App';
import { LANGUAGE_VERSION } from '../../properties';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    offset: theme.mixins.toolbar
}));

const ElevationScroll = (props) => {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const RootContainer = (props) => {
    const classes = useStyles();
    const [labelsData, setLabelsData] = useState({});
    const [drawerIsOpen, toggleDrawer] = useState(false);
    const history = useHistory();
    const language = props.selectedLanguage;

    useEffect(() => {
        fetch(`content/Main${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setLabelsData(json));
    }, []);

    const languageData = _.get(labelsData, language, {});
    if (!languageData) {
        return <div></div>
    }

    const links = props.pages;
    const mapLinks = ({ label, path }) => {
        const isContactLabel = _.isEqual(label, 'contact');
        const linkLabel = _.get(languageData, `pages.${label}`);
        return (
            <Button
                color={isContactLabel ? "secondary" : "inherit"}
                component={Link}
                to={path}
                className="appBar-link"
                variant={isContactLabel ? "contained" : "text"}
                key={`${label}-key`}
            >
                {linkLabel || label}
            </Button>
        );
    };
    const mapFooterLinks = ({ label, path }) => {
        const linkLabel = _.get(languageData, `pages.${label}`);
        return (
            <Button
                color="inherit"
                component={Link}
                to={path}
                className="appBar-link"
                key={`${label}-key`}
            >
                {linkLabel || label}
            </Button>
        );
    };
    const mobileLinks = (
        <React.Fragment key="right">
            <IconButton onClick={() => toggleDrawer(true)} color="inherit"><DehazeIcon /></IconButton>
            <Drawer anchor="right" open={drawerIsOpen} onClose={() => toggleDrawer(false)}>
                <div
                    className="mobile-drawer"
                    role="presentation"
                    onClick={() => toggleDrawer(false)}
                    onKeyDown={() => toggleDrawer(false)}
                >
                    <List>
                        {links.map(mapLinks).map((button, index) => (
                            <ListItem key={index + 'button'} button>
                                {button}
                            </ListItem>
                        ))}
                    </List>
                </div>
            </Drawer>
        </React.Fragment>
    );

    const onBannerClick = () => {
        history.push(LINK_PAGE.HOME.path);
    }

    const AvantreInfoRow = ({ icon, iconSize, children }) => (
        <div className="avantre-info-row">
            <div className="avantre-contact-icon">
                <img
                    alt="avantre-info-icon"
                    src={icon}
                    title="avantre-info-icon"
                    height={iconSize || '96px'}
                />
            </div>
            <div className="avantre-contact-info">
                {children}
            </div>
        </div>
    );

    return (
        <React.Fragment>
            <CssBaseline />
            <ThemeProvider theme={defaultTheme}>
                <header>
                    <ElevationScroll>
                        <AppBar position="fixed" id="AppBanner">
                            <Container maxWidth="lg" disableGutters>
                                <Toolbar>
                                    <img
                                        className="appBanner-group cursor-pointer"
                                        alt="group"
                                        src="img/icons8-people-group.png"
                                        title="group"
                                        height="48px"
                                        onClick={onBannerClick}
                                    />
                                    <Typography variant="h5" className={`${classes.title} cursor-pointer`} onClick={onBannerClick}>
                                        {languageData?.companyName || 'Avantre Group'}
                                    </Typography>
                                    <Hidden smDown>
                                        <SelectLanguage
                                            selectedLanguage={props.selectedLanguage}
                                            languages={languages}
                                            onLanguageChange={props.onLanguageChange}
                                        />
                                        {links.map(mapLinks)}
                                    </Hidden>
                                    <Hidden mdUp>
                                        <SelectLanguage
                                            selectedLanguage={props.selectedLanguage}
                                            languages={languages}
                                            onLanguageChange={props.onLanguageChange}
                                            variant="mobile"
                                        />
                                        {mobileLinks}
                                    </Hidden>
                                </Toolbar>
                            </Container>
                        </AppBar>
                    </ElevationScroll>
                    <div className={classes.offset} />
                </header>
                <main>
                    <div className="RootContainer-root">
                        {props.children}
                    </div>
                </main>
                <footer>
                    <div className="RootContainer-footer">
                        <Container maxWidth="lg" disableGutters>
                            <div className="footer-navigation">
                                <div className="footer-navigation-info">
                                    <Typography variant="h2">{languageData?.companyName || 'Avantre Group'}</Typography>
                                    <Typography variant="subtitle1">{languageData?.companyOfficialName}</Typography>
                                    <Typography variant="subtitle1">{languageData?.street}</Typography>
                                    <Typography variant="subtitle1">{languageData?.postalCode}</Typography>
                                    <Typography variant="subtitle1">{languageData?.identityNumber}</Typography>
                                </div>
                                <div className="footer-navigation-menu">
                                    {links.map(mapFooterLinks)}
                                </div>
                                <div className="footer-navigation-contact">
                                    <AvantreInfoRow icon="img/icons8-call.png" iconSize="48px">
                                        <Typography variant="subtitle1">{languageData?.phoneNoAvantre}</Typography>
                                    </AvantreInfoRow>
                                    <Divider variant="inset" light />
                                    <AvantreInfoRow icon="img/icons8-mail.png" iconSize="48px">
                                        <Typography variant="subtitle1">{languageData?.emailAvantre}</Typography>
                                    </AvantreInfoRow>
                                </div>
                            </div>
                            <div className="footer-rules">
                                Copyright © 2020 Wszelkie prawa zastrzeżone &bull; Icons copyright © by
                                <MaterialLink href="https://icons8.com/" color="secondary" target="_blank" component="a" > icons8.com</MaterialLink>
                            </div>
                        </Container>
                    </div>
                </footer>
            </ThemeProvider>
        </React.Fragment >
    );
};

export default RootContainer;