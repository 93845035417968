import _ from 'lodash';
import React from 'react';
import { Container, Divider, Hidden, Paper, Typography } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const LabourLeasing = ({ offerLeasing, languageOfferData }) => {
    return (
        <Container maxWidth="lg" className="Offer-benefits-section" disableGutters>
            <Typography variant="h5" color="primary" align="center" className="default-margin-bottom">
                {languageOfferData?.title}
            </Typography>
            <Paper elevation={1} component="div" className="Offer-benefits-container">
                <div className="Offer-benefits-image">
                    <img
                        alt="Offer-benefits"
                        src="img/icons8-inspection.png"
                    />
                </div>
                <Hidden smDown>
                    <Divider orientation="vertical" flexItem />
                </Hidden>
                <div className="Offer-benefits-content">
                    <Typography variant="h4" color="secondary">
                        {offerLeasing?.title}
                    </Typography>
                    <div className="list">
                        {offerLeasing && offerLeasing?.benefitsOfWorkingList.map(text => <div key={_.trim(text)} className="list-item">
                            <CheckBoxIcon color="secondary" />{text}
                        </div>)}
                    </div>
                </div>
            </Paper>
        </Container>
    );
};

export default LabourLeasing;