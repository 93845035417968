import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { init } from 'emailjs-com';
import About from './components/about/About';
import Contact from './components/contact/Contact';
import RootContainer from './components/container/RootContainer';
import Home from './components/home/Home';
import Collaboration from './components/collaboration/Collaboration';
import Offer from './components/offer/Offer';
import { DEFAULT_LANGUAGE, REACT_EMAILJS_APP_USER_ID } from './properties';
import './sass/main.scss';

init(REACT_EMAILJS_APP_USER_ID);

export const languages = [
    { icon: 'img/flags/icons8-poland-48.png', label: 'Polski', value: 'PL' },
    { icon: 'img/flags/icons8-great-britain-48.png', label: 'English', value: 'EN' },
    { icon: 'img/flags/icons8-germany-48.png', label: 'Deutsch', value: 'DE' },
    { icon: 'img/flags/icons8-russian-federation-48.png', label: 'русский', value: 'RU' }
];

export const pages = [
    { label: 'home', path: '/' },
    { label: 'offer', path: '/offer' },
    { label: 'about', path: '/about' },
    { label: 'collaboration', path: '/collaboration' },
    { label: 'contact', path: '/contact' }
];

export const LINK_PAGE = {
    HOME: pages[0],
    OFFER: pages[1],
    ABOUT: pages[2],
    COLLABORATION: pages[3],
    CONTACT: pages[4]
};

function App () {
    const [selectedLanguage, setLanguage] = useState(DEFAULT_LANGUAGE);

    useEffect(() => {
        const systemLanguage = (
            (navigator?.languages && navigator?.languages[0]) ||
            navigator?.language ||
            navigator?.userLanguage
        );
        const languageDetected = languages.some(entry => systemLanguage.includes(entry.value.toLowerCase()));
        if (languageDetected) {
            const appLanguage = languages.find(entry =>
                systemLanguage.includes(entry.value.toLowerCase())
            );
            setLanguage(appLanguage.value);
            document.documentElement.setAttribute("lang", systemLanguage);
        } else {
            document.documentElement.setAttribute("lang", DEFAULT_LANGUAGE);
        }
    }, [])

    useEffect(() => {
        document.documentElement.setAttribute("lang", selectedLanguage);
    }, [selectedLanguage])

    const onLanguageChange = (entry) => {
        setLanguage(entry.value);
    };

    const pageOptions = {
        selectedLanguage,
        onLanguageChange
    };
    return (
        <div className="App">
            <Router>
                <RootContainer pages={pages} {...pageOptions}>
                    <Switch>
                        <Route path={LINK_PAGE.CONTACT.path}>
                            <Contact breadcrumbInfo={LINK_PAGE.CONTACT} {...pageOptions} />
                        </Route>
                        <Route path={LINK_PAGE.COLLABORATION.path}>
                            <Collaboration breadcrumbInfo={LINK_PAGE.COLLABORATION} {...pageOptions} />
                        </Route>
                        <Route path={LINK_PAGE.ABOUT.path}>
                            <About breadcrumbInfo={LINK_PAGE.ABOUT} {...pageOptions} />
                        </Route>
                        <Route path={LINK_PAGE.OFFER.path}>
                            <Offer breadcrumbInfo={LINK_PAGE.OFFER} {...pageOptions} />
                        </Route>
                        <Route path={LINK_PAGE.HOME.path}>
                            <Home breadcrumbInfo={LINK_PAGE.HOME} {...pageOptions} />
                        </Route>
                    </Switch>
                </RootContainer>
            </Router>
        </div>
    );
}

export default App;
