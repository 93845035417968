import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { Paper, Container, Typography, TextField, Button, Snackbar, Hidden, CircularProgress } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { LANGUAGE_VERSION, REACT_EMAILJS_APP_SERVICE_ID, REACT_EMAILJS_APP_TEMPLATE_ID, REACT_EMAILJS_APP_USER_ID } from '../../properties';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Contact = ({ breadcrumbInfo, selectedLanguage }) => {
    const [langData, setLangData] = useState({});
    const [emailData, setEmailData] = useState({
        name: '',
        email: '',
        content: ''
    });
    const [emailError, setEmailError] = useState('');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState({ open: false, error: false });
    const language = selectedLanguage;
    const languageData = _.get(langData, language, {});

    useEffect(() => {
        fetch(`content/Contact${LANGUAGE_VERSION}.json`)
            .then(response => response.json())
            .then(json => setLangData(json));
    }, []);

    const isValidEmail = (email) => {
        /* eslint-disable no-useless-escape */
        const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(String(email).toLowerCase()) || _.isEmpty(email);
    }

    useEffect(() => {
        const isEmailValid = isValidEmail(emailData.email);
        if (isEmailValid) {
            setEmailError('');
        } else {
            setEmailError(languageData?.emailError);
        }
    }, [emailData, languageData?.emailError]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const onTextFieldChange = (textField, value) => {
        setEmailData(state => ({ ...state, [textField]: value }));
    };

    const onSubmit = async () => {
        const templateParams = {
            from_name: emailData.name,
            message: emailData.content,
            to_email: emailData.email,
            reply_to: emailData.email
        };
        setLoading(true);
        emailjs
            .send(
                REACT_EMAILJS_APP_SERVICE_ID,
                REACT_EMAILJS_APP_TEMPLATE_ID,
                templateParams,
                REACT_EMAILJS_APP_USER_ID
            )
            .then(() => setSnackbarOpen({ open: true, error: false }))
            .catch(error => {
                console.log(error);
                setSnackbarOpen({ open: true, error: true });
            })
            .finally(() => {
                setEmailError('');
                setEmailData({
                    name: '',
                    email: '',
                    content: ''
                });
                setLoading(false);
            });
    };
    const submitDisabled = _.isEmpty(emailData.name) || !isValidEmail(emailData.email) || _.isEmpty(emailData.email) || _.isEmpty(emailData.content);

    return (
        <div className="ContactUs">
            <section className="Home-about-us" style={{ backgroundImage: `url('/img/contact.jpg')` }} >
                <Container maxWidth="lg" disableGutters>
                    <Typography variant="h2" gutterBottom>
                        {languageData?.title}
                    </Typography>
                    <div className="header-contact">
                        <div className="header-contact-phone">
                            <Hidden mdUp>
                                <img
                                    alt="phone"
                                    src="img/icons8-call.png"
                                    title="phone"
                                    height="50px"
                                />
                            </Hidden>
                            <Hidden smDown>
                                <img
                                    alt="phone"
                                    src="img/icons8-call.png"
                                    title="phone"
                                    height="80px"
                                />
                            </Hidden>
                            <Typography variant="h4">
                                {languageData?.phoneNoAvantre}
                            </Typography>
                        </div>
                        <div className="header-contact-email">
                            <Hidden mdUp>
                                <img
                                    alt="email"
                                    src="img/icons8-mail.png"
                                    title="email"
                                    height="50px"
                                />
                            </Hidden>
                            <Hidden smDown>
                                <img
                                    alt="email"
                                    src="img/icons8-mail.png"
                                    title="email"
                                    height="80px"
                                />
                            </Hidden>
                            <Typography variant="h4">
                                {languageData?.emailAvantre}
                            </Typography>
                        </div>
                    </div>
                    <div className="header-description">
                        <Typography variant="h5">
                            {languageData?.description}
                        </Typography>
                    </div>
                </Container>
            </section>
            <section>
                <Container maxWidth="lg" disableGutters>
                    <Paper
                        component="form"
                        elevation={3}
                        className="contactForm"
                        noValidate
                        autoComplete="off"
                    >
                        <div className="contactForm-header">
                            <div className="contactForm-name">
                                {loading ? <Skeleton /> : <TextField
                                    id="contact-name"
                                    label={languageData?.labelName}
                                    onChange={event => onTextFieldChange('name', event?.target?.value)}
                                    color="secondary"
                                    fullWidth
                                    required
                                    autoFocus
                                />}
                            </div>
                            <div className="contactForm-email">
                                {loading ? <Skeleton /> : <TextField
                                    id="contact-email"
                                    label={languageData?.labelEmail}
                                    onChange={event => onTextFieldChange('email', event?.target?.value)}
                                    color="secondary"
                                    fullWidth
                                    required
                                    type="email"
                                    error={!_.isEmpty(emailError)}
                                    helperText={emailError}
                                />}
                            </div>
                        </div>
                        <div className="contactForm-content">
                            {loading ? <Typography variant="h1"><Skeleton /></Typography> : <TextField
                                id="contact-content"
                                color="secondary"
                                label={languageData?.labelMessage}
                                onChange={event => onTextFieldChange('content', event?.target?.value)}
                                multiline
                                rows={4}
                                fullWidth
                                required
                            />}
                        </div>
                        <div className="contactForm-buttons">
                            {loading && <div className="contactForm-buttons-loading"><CircularProgress /></div>}
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                disabled={submitDisabled || loading}
                                onClick={onSubmit}
                            >
                                {languageData?.labelSend || "Submit"}
                            </Button>
                        </div>
                    </Paper>
                </Container>
            </section>
            <Snackbar open={snackbarOpen.open} autoHideDuration={6000} onClose={handleClose}>
                {snackbarOpen.error ?
                    <Alert onClose={handleClose} severity="error">
                        {languageData?.emailSendError || "Sent email failed"}
                    </Alert> :
                    <Alert onClose={handleClose} severity="success">
                        {languageData?.emailSendSuccess || "Message was sent successfully"}
                    </Alert>
                }
            </Snackbar>
        </div>
    );
};

export default Contact;