import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme({
    "typography": {
        "fontSize": 24,
    },
    "palette": {
        "common": {
            "black": "#000",
            "white": "#fff"
        },
        "background": {
            "paper": "#fff",
            "default": "#fafafa"
        },
        "primary": {
            "light": "rgba(142, 172, 188, 1)",
            "main": "rgba(52, 81, 95, 1)",
            // "main": "rgba(96, 125, 140, 1)",
            "dark": "rgba(52, 81, 95, 1)",
            "contrastText": "#fff"
        },
        "secondary": {
            "light": "rgba(255, 201, 71, 1)",
            "main": "rgba(255, 152, 0, 1)",
            "dark": "rgba(198, 105, 0, 1)",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgba(0, 0, 0, 0.87)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)"
        }
    }
});

export default defaultTheme;