import React, { Fragment, useState } from 'react';
import { Button, MenuItem, ListItemIcon, Avatar, Typography, Menu } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.secondary.contrastText,
    },
    orange: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    },
}));

const SelectLanguage = ({ selectedLanguage, languages, onLanguageChange, variant }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const ButtonContent = (props) => {
        return (
            <Fragment>
                <ListItemIcon>
                    <Avatar alt={props.entry.value} src={props.entry.icon} className={classes.orange} />
                </ListItemIcon>
                {props.variant !== 'mobile' ?
                    <Typography variant="inherit">{props.entry.label}</Typography> :
                    <Typography variant="inherit">{props.entry.value}</Typography>
                }
            </Fragment>
        );
    }

    return (
        <div className="SelectLanguage">
            <Button
                className="SelectLanguage-button"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<ExpandMoreIcon color="inherit" />}
            >
                <ButtonContent entry={languages.find(entry => selectedLanguage === entry.value)} variant={variant} />
            </Button>
            <Menu
                className="SelectLanguage-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {languages.map(entry => (
                    <MenuItem
                        key={`${entry.value}-key`}
                        onClick={() => {
                            onLanguageChange(entry);
                            handleClose();
                        }}
                    >
                        <ButtonContent entry={entry} />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default SelectLanguage;